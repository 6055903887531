import { render, staticRenderFns } from "./OrderShipping.vue?vue&type=template&id=7bb7849b&scoped=true&"
import script from "./OrderShipping.vue?vue&type=script&lang=js&"
export * from "./OrderShipping.vue?vue&type=script&lang=js&"
import style0 from "./OrderShipping.vue?vue&type=style&index=0&id=7bb7849b&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bb7849b",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {YfnIcon: require('/home/php1/m-nuxt/components/YfnIcon.vue').default})
